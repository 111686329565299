@media (min-width: 768px) {
    .totalRecordCount {
        font-size: medium;
    }

    .query-button {
        /*background-color: #343A40 !important;*/
        background-color: #000000 !important;
        width: 9rem !important;
        border-radius: 3rem !important;
        padding: 3px 0px !important;
    }
}

@media (max-width: 767px) {
    .totalRecordCount {
        font-size: medium;
        margin: 2px;
    }

    .query-button {
        /*background-color: #343A40 !important;*/
        background-color: #000000 !important;
        width: 100% !important;
        border-radius: 3rem !important;
        /*font-size: smaller !important;*/
        /*padding: 4px 5px !important;*/
        margin: 8px !important;
    }
}

.view-full-record-button {
    padding: 0.1rem 0.8rem !important;
    margin-top: 0.1rem !important;
    border-radius: 1rem !important;
    font-size: 0.9rem !important;

}

@media (max-width: 767px) {
    .view-full-record-button {
        /*padding: 0.1rem 0.8rem !important;*/
        margin-top: 0.1rem !important;
        border-radius: 1rem !important;
        /*font-size: 0.9rem !important;*/
        width: 5rem !important;
        border-radius: 1rem !important;
        font-size: smaller !important;
        padding: 4px 5px !important;
    }
}

.full-table-record-viewing-container {
    max-height: 550px !important;
    /*overflow-y: scroll !important;*/
    overflow-y: auto !important;
    overscroll-behavior: none !important;
    z-index: 2 !important;
    position: relative !important;
    padding: 0px 15px !important;
    touch-action: "pan-y" !important;
    align-items: start !important;
    padding: 0 0 !important;
    margin: 0 0 !important;
}

.full-table-record-viewing {
    border-collapse: separate;
    border-spacing: 0 0.9rem;
    padding: 2px;
}

.full-table-record-viewing thead th {
    border-spacing: 0 0;
    background: rgb(0, 0, 0);
    color: white;
    text-align: center;
    position: sticky;
    top: 0;
    z-index: 2;
}

.full-table-record-viewing thead tr th:nth-child(1) {
    border-radius: 10px 0px 0px 10px;
}

.full-table-record-viewing thead tr th:nth-last-child(1) {
    border-radius: 0 10px 10px 0;
}

.full-table-record-viewing tbody tr td {
    padding: 8px 10px 8px 10px;
    color: #5a5a5a;
    vertical-align: middle;
    /*border-top: 1px solid #e8e8e8;*/
    border-bottom: 1px solid #e8e8e8;
    font-size: smaller;
    text-align: center;
}

.full-table-record-viewing tbody tr td:nth-child(1) {
    border-radius: 10px 0px 0px 10px;
    border-left: 1px solid #e8e8e8;
    /*box-shadow: 2px 1px 4px 0px #E0E0E0;*/
    box-shadow: 0.2rem 0.1rem 0.2rem rgba(0, 0, 0, 0.05);
}

.full-table-record-viewing tbody tr td:nth-last-child(1) {
    border-radius: 0 10px 10px 0;
    border-right: 1px solid #e8e8e8;
    /*box-shadow: 2px 1px 4px 0px #E0E0E0;*/
    box-shadow: 0.2rem 0.1rem 0.2rem rgba(0, 0, 0, 0.05);
}