.om-feedback-button {
    background-color: black;
    color: white;
    z-index: 3;
    top: 50%;
    font-size: 15px;
    border-radius: 6px 0px 0px 6px;
    position: fixed;
    right: 0px;
    transform: translateY(-50%);
    border: none;
    outline: none;
    cursor: pointer;
    white-space: nowrap;
    letter-spacing: .2px;
}

.om-feedback-button span {
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    text-decoration: none;
}

@media screen and (max-height: 100vh) {
    .om-feedback-button {
        position: sticky;
        float: right;
        margin-top: calc(50vh - 50%);
        transform: translateY(-50%);
        padding: 12px 10px 8px 10px;
    }
}

/* Handling for medium screen*/
@media screen and (max-height: 100vh) and (max-width: 1200px) {
    .om-feedback-button {
        position: sticky;
        float: right;
        margin-top: calc(29vh - 50%);
        transform: translateY(-50%);
        /*padding: 8px 6px 8px 6px;*/
        padding: 12px 10px 8px 10px;
    }
}

/* Handling for small screen*/
@media screen and (max-height: 100vh) and (max-width: 767px) {
    .om-feedback-button {
        position: sticky;
        float: right;
        margin-top: calc(5vh - 50%);
        transform: translateY(-50%);
        font-size: 13px;
    }

    @supports (-webkit-touch-callout: none) {

        /* CSS for iPhone */
        .om-feedback-button {
            padding: 9px 22px 6px 6px;
        }
    }

    @supports not (-webkit-touch-callout: none) {

        /* CSS for other devices */
        .om-feedback-button {
            padding: 10px 3px 5px 5px;
        }
    }
}




/*@media screen and (max-width: 767px) {
    .om-feedback-button {
        background-color: black;
        color: white;
        position: fixed;
        float: right;
        top: 60%;
        right: 0px;
        padding: 8px 6px 6px 6px;
        font-size: 12px;
    }
}*/