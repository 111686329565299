html,
body {
  height: 100%;
  overflow: hidden;
}

/*body {
  position: fixed;
  width: 100%;
}*/

.container {
  height: 100%;
  overflow: auto;
}

@keyframes fadeInUp {
  from {
    transform: translate3d(0px, 10px, 0)
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1
  }
}

@-webkit-keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0)
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1
  }
}

/*.m-1 {
  margin: 12px !important;
}*/

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: both
}

.animatedFadeInUp {
  opacity: 0
}

.fadeInUp {
  opacity: 0;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
}

a {
  color: #A52323 !important
}

a:hover {
  color: #ff0000 !important
}

.om-link {
  color: #A52323 !important
}

.om-link:hover {
  color: #A52323 !important
}

.om-button {
  /*background-color: #343A40 !important;*/
  background-color: #000000 !important;
  border-radius: 2rem !important;
}

@media (min-width: 768px) {
  .om-button {
    /*width: auto;*/
    /*background-color: #343A40;*/
    background-color: #000000 !important;
    width: 13rem;
    border-radius: 1rem;
  }
}

@media (max-width: 767px) {
  .om-button {
    background-color: #000000 !important;
    /*background-color: #343A40;*/
    width: 100%;
    border-radius: 1rem;
  }
}

.about-button-hover-effect {
  margin: 5px 8px;
  font-family: arial, sans-serif !important;
  color: grey !important;
  text-decoration: none !important;
  border: none !important;
  place-content: center !important;
}

.about-button-hover-effect:hover,
.gradient-hover-effect:focus {
  text-decoration-line: underline !important;
  color: grey !important;
}

/*hover effect on next, previous button in data dict and data preview*/
.image-wrapper {
  position: relative;
  display: inline-block;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
}

.image-wrapper:hover .overlay {
  opacity: 1;
}

.image-wrapper:hover .overlay:hover {
  background-color: #fff;
}

/*.button-on-card {
  width: 10rem;
  border-radius: 1rem;
}*/

.nav-tabs {
  margin: 12px 0 12px 0 !important;
}

.nav-tabs .nav-link {
  /* Default tab */
  border: none !important;
  background-color: transparent !important;
  color: #333 !important;
  padding: 6px !important;
  /*margin-left: 3px;
  margin-right: 3px;*/
  font-size: 1em !important;
  /*transition: color 0.2s ease;*/
}

.nav-tabs .nav-link:hover {
  /* Hover tab */
  color: #A52323 !important;
  background-color: #ffffff !important;
  text-decoration: none !important;
  font-weight: bold !important;
  border: none !important;
}

.nav-tabs .nav-link.active {
  /* Active tab */
  color: #A52323 !important;
  border: none !important;
  border-bottom: 3px solid #A52323 !important;
  text-decoration: none !important;
  font-weight: bold !important;
}

/* CSS for Tab */
@media (max-width: 767px) {
  .nav-tabs .nav-link {
    font-size: 0.8em;
  }
}

/* General hover effect from black -> red with 2s delay*/
@media (min-width: 768px) {
  .red-gradient-hover-effect {
    /*display: flex;*/
    background: #000000;
    font-family: inherit;
    color: #fff;
    text-decoration: none;
    transition: background-color 2s !important;
    border: none !important;
    place-content: center;
  }

  .red-gradient-hover-effect:hover,
  .red-gradient-hover-effect:focus {
    background-color: #e40101 !important;
    color: #ffffff;
    text-decoration: none;
    border: none !important;
  }
}

/*Hover effect for view data button*/
@media (min-width: 768px) {
  .black-gradient-hover-effect {
    /*background: #343A40 !important;*/
    background: #000000 !important;
    font-family: inherit !important;
    color: #fff !important;
    text-decoration: none !important;
    border: none !important;
    transition: background-color 2s !important;
    place-content: center !important;
  }

  .black-gradient-hover-effect:hover,
  .black-gradient-hover-effect:focus {
    /*background-color: #000000 !important;*/
    background-color: #343A40 !important;
    color: #ffffff !important;
  }
}

.add-to-cart-button {
  border: none !important;
  background-color: #ffffff !important;
  color: #000000 !important;
  font-size: 1.3rem !important;
}